import "firebase/compat/storage";
import { call, takeLatest, put, take } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";
import dbRef from "../../firebase-api/dbRef";
import fbRefs from "../../libs/fbRef";
import Helper from "../../util/helper";

function* updateStudent(request) {
    try {
        const { firebase, values } = request.payload;
        let storagePath = firebase.sbp + "/media/images/";
        if (values.profileImageUrl && typeof values.profileImageUrl !== 'string') {
            let photourl = yield call(Helper.getAttachedMediaPath, storagePath, values.profileImageUrl, firebase);
            if (photourl) {
                values.profileImageUrl = photourl[0].path
            }
        }
        let endpoint = "bookingApi/student"
        if (values.id) {
            endpoint += "/" + values.id
        }
        let response = yield call(callApi, firebase, "post", endpoint, values);
        if (response.status == 200) {
            notification("success", formatMsg(values.id ? "studentEditedSuccess" : "studentSavedSuccess"));
            yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { operationType: "STUDENT_UPDATE", isLoading: false } });
        }
        else {
            yield put({ type: actions.STUDENT_REQUEST_FAILED });
            notification("error", formatMsg(response.body.message));
        }
    } catch (err) {
        yield put({ type: actions.STUDENT_REQUEST_FAILED });
        Helper.notifyBugsnag(err, true);
        console.log("failed to save student ", err);
    }
}

function* addSibling(request) {
    try {
        const { firebase, values } = request.payload;

        values.student.centers = firebase.student.centers; 

        let endpoint = "bookingApi/student/sibling";
        let response = yield call(callApi, firebase, "post", endpoint, values);
        if (response.status == 200) {
            notification("success", formatMsg("siblingSuccess"));
            yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { operationType: "SIBLING_ADDED", addSiblingLoader: false } });
        }
        else {
            yield put({ type: actions.STUDENT_REQUEST_FAILED });
            notification("error", formatMsg(response.body.message));
        }
    } catch (err) {
        yield put({ type: actions.STUDENT_REQUEST_FAILED });
        Helper.notifyBugsnag(err, true);
        console.log("failed to add sibling", err);
    }
}

function* addParent(request) {
    try {
        const { firebase, values } = request.payload;
        let storagePath = firebase.sbp + "/media/images/";
        if (values.profilePhoto && typeof values.profilePhoto !== 'string') {
            let photourl = yield call(Helper.getAttachedMediaPath, storagePath, values.profilePhoto, firebase);
            if (photourl) {
                values.profilePhoto = photourl[0].path
            }
        }

        let endpoint = "bookingApi/parent"
        if (values.id) {
            endpoint += "/" + values.id
        }
        let response = yield call(callApi, firebase, "post", endpoint, values);
        if (response.status == 200) {
            notification("success", formatMsg(values.id ? "updateParentSuccess" : "addParentSuccess"));
            yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { operationType: "ADD_PARENT", isLoading: false } });
        }
        if (response.status == 201) {
            yield put({
                type: actions.STUDENT_REQ_SUCCESS, payload: {
                    isAlreadyExistForSomeOtherChild: response.body.isAlreadyExistForSomeOtherChild,
                    AlreadyExistMessage: response.body.message,
                    isLoading: false
                }
            });
        }

    } catch (err) {
        yield put({ type: actions.STUDENT_REQUEST_FAILED });
        Helper.notifyBugsnag(err, true);

        console.log("failed to save parent ", err);
    }
}

function* fetchParents(request) {
    try {
        const { firebase, studentId } = request.payload;
        const parentChan = yield call(dbRef.getEntitiesByChannel, firebase, fbRefs.parentRef, studentId, true, "studentId");
        while (true) {
            let parentList = yield take(parentChan);
            yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { parentList, parentChan, isLoading: false } });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({
            type: actions.STUDENT_REQUEST_FAILED
        });
        console.log("failed to fetch parents", err);
    }
}

function* deleteParent(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = "bookingApi/parent/" + id;

        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response.status == 200) {
            notification("success", formatMsg("parentDeleteSuccess"));
            yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { operationType: "PARENT_DELETE", isLoading: false } });
        }
        else {
            yield put({ type: actions.STUDENT_REQUEST_FAILED });
            notification("error", formatMsg(response.body.message));
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STUDENT_REQUEST_FAILED });

        console.log("failed to delete parent", err);
    }
}

function* fetchStudentPackages(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = `bookingApi/student/${id}/bookings`;

        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { operationType: "FETCH_STUDENT_PACKAGES", isLoading: false, studentPackages: response.body.data } });
        }
        else {
            yield put({ type: actions.STUDENT_REQUEST_FAILED });
            notification("error", formatMsg(response.body.message));
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STUDENT_REQUEST_FAILED });

        console.log("failed to fetch student packages", err);
    }
}

function* confirmBooking(request) {
    try {
        const { firebase, reqObj } = request.payload;
        let endpoint = `bookingApi/booking/${reqObj.id}/confirm`;

        let response = yield call(callApi, firebase, "post", endpoint, reqObj);
        if (response.status == 200) {
            yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { operationType: "CONFIRM_BOOKING", isLoading: false } });
        }
        else {
            yield put({ type: actions.STUDENT_REQUEST_FAILED });
            notification("error", formatMsg(response.body.message));
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STUDENT_REQUEST_FAILED });

        console.log("failed to confirm booking", err);
    }

}
function* cancelBooking(request) {
    try {
        const { firebase, reqObj } = request.payload;
        let endpoint = `bookingApi/booking/${reqObj.id}/cancel`;

        let response = yield call(callApi, firebase, "post", endpoint, reqObj);
        if (response.status == 200) {
            yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { operationType: "CANCEL_BOOKING", isLoading: false } });
        }
        else {
            yield put({ type: actions.STUDENT_REQUEST_FAILED });
            notification("error", formatMsg(response.body.message));
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STUDENT_REQUEST_FAILED });

        console.log("failed to cancel booking", err);
    }
}
function* fetchStudentSchedules(request) {
    try {
        const { firebase, id, reqObj } = request.payload;
        let endpoint = `bookingApi/student/${id}/bookingSchedules` + "?startDate=" + reqObj.startDate + "&endDate=" + reqObj.endDate
        let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
        if (response.status == 200) {
            yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { operationType: "FETCH_STUDENT_SCHEDULES", isLoading: false, studentSchedules: response.body.data } });
        }
        else {
            yield put({ type: actions.STUDENT_REQUEST_FAILED });
            notification("error", formatMsg(response.body.message));
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STUDENT_REQUEST_FAILED });

        console.log("failed to fetch student schedules", err);
    }
}

function* getAllStudent(request) {
    try {
        const { firebase, } = request.payload;
        let endpoint = "bookingApi/student/all/students"
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            let students = response.body.data?.students.filter((item) => !item.deleted) || [];
            yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { students, isLoading: false } });
        }
        else {
            yield put({ type: actions.STUDENT_REQUEST_FAILED });
            notification("error", formatMsg(response.body.message));
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STUDENT_REQUEST_FAILED });

        console.log("failed get all student ", err);
    }
}

function* getAllStudentPackages(request) {
    try {
        const { firebase, startDate, endDate } = request.payload;
        let endpoint = "bookingApi/student/all/studentPackages" + "?startDate=" + startDate + "&endDate=" + endDate
        let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
        if (response.status == 200) {
            let studentPackages = response.body.data?.studentPackages || [];
            yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { studentPackages, studentPackagesLoading: false } });
        }
        else {
            yield put({ type: actions.STUDENT_REQUEST_FAILED });
            notification("error", formatMsg(response.body.message));
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STUDENT_REQUEST_FAILED });

        console.log("failed get all student ", err);
    }
}

function* deleteStudent(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = "bookingApi/student/" + id
        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response.status == 200) {
            yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { isLoading: false, operationType: "DELETE_STUDENT" } });
            notification("success", formatMsg("deleteStudentSuccess"));
        }
        else {
            yield put({ type: actions.STUDENT_REQUEST_FAILED });
            notification("error", formatMsg(response.body.message));
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STUDENT_REQUEST_FAILED });

        console.log("failed delete student ", err);
    }
}

function* getStudentDetailById(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = "bookingApi/student/" + id
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            const studentDetail = response.body.data; 
            const currStudParentNumbersSet: Set<number> = new Set(); 
            const currStudParentEmailsSet: Set<string> = new Set(); 

            if (studentDetail.parents && studentDetail.parents.length > 0) {
                const parentsArray = studentDetail.parents;
                parentsArray.forEach((parentObj) => {
                    if (parentObj.phoneNumber) currStudParentNumbersSet.add(parentObj.phoneNumber); 
                    if (parentObj.email) currStudParentEmailsSet.add(parentObj.email); 
                }); 
            }

            yield put({ 
                type: actions.STUDENT_REQ_SUCCESS, 
                payload: { 
                    isLoading: false, 
                    studentDetail, 
                    currStudParentNumbersSet, 
                    currStudParentEmailsSet 
                } 
            });
        }
        else {
            yield put({ type: actions.STUDENT_REQUEST_FAILED });
            notification("error", formatMsg(response.body.message));
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STUDENT_REQUEST_FAILED });

        console.log("failed delete student ", err);
    }
}

function* sendInviteToParent(request) {
    try {
        const { firebase, list } = request.payload;
        let endpoint = `bookingApi/invite/parent`
        let response = yield call(callApi, firebase, "post", endpoint, { list });
        if (response.status == 200) {
            notification("success", formatMsg(response.body.message));
            yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { operationType: "SENT_PARENT_INVITE", isLoading: false } });
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.STUDENT_REQUEST_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);

        yield put({ type: actions.STUDENT_REQUEST_FAILED });
        console.log("failed to send parent invite", err);
    }
}

export default function* rootSaga() {

    yield takeLatest(actions.UPDATE_STUDENT, updateStudent);
    yield takeLatest(actions.ADD_PARENT, addParent);
    yield takeLatest(actions.FETCH_PARENTS, fetchParents);
    yield takeLatest(actions.DELETE_PARENT, deleteParent);
    yield takeLatest(actions.FETCH_STUDENT_PACKAGES, fetchStudentPackages);
    yield takeLatest(actions.CONFIRM_BOOKING, confirmBooking);
    yield takeLatest(actions.CANCEL_BOOKING, cancelBooking);
    yield takeLatest(actions.FETCH_STUDENT_SCHEDULES, fetchStudentSchedules);
    yield takeLatest(actions.GET_ALL_STUDENT, getAllStudent);
    yield takeLatest(actions.GET_ALL_STUDENT_PACKAGES, getAllStudentPackages);
    yield takeLatest(actions.DELETE_STUDENT, deleteStudent);
    yield takeLatest(actions.GET_STUENT_DETAIL_BY_ID, getStudentDetailById);
    yield takeLatest(actions.SEND_INVITE_TO_PARENTS, sendInviteToParent);
    yield takeLatest(actions.ADD_SIBLING, addSibling);
}